.personal-info {
  display: grid;
  grid-template:
    ". dude . firstName lastName" auto
    ". dude . email phone" auto /
    0.5fr auto 0.25fr 2fr 2fr;
  justify-content: center;
  border: none;
  padding-top: 1.6rem;
  @media (max-width: 1100px) {
    grid-template:
      ". dude . firstName" auto
      ". dude . lastName" auto
      ". dude . email" auto
      ". dude . phone" auto /
      0.5fr auto 0.25fr 4fr;
  }
  @media (max-width: 750px) {
    grid-template:
      "dude" auto
      "firstName" auto
      "lastName" auto
      "email" auto
      "phone" auto /
      auto;
  }
  > div {
    padding: 0 6% 0 6%;
    @media (max-width: 750px) {
      padding: 0 5% 0 5%;
    }
  }
  &__dude {
    grid-area: dude;
    justify-self: end;
    @media (max-width: 1100px) {
      align-self: center;
    }
    @media (max-width: 750px) {
      justify-self: center;
    }
  }
  &__firstName {
    grid-area: firstName;
    @media (max-width: 750px) {
      margin-top: 3rem;
    }
  }
  &__lastName {
    grid-area: lastName;
    @media (max-width: 1100px) {
      margin-top: 1.9rem;
    }
  }
  &__email {
    grid-area: email;
    margin-top: 2.1rem;
    @media (max-width: 1100px) {
      margin-top: 1.9rem;
    }
  }
  &__phone {
    grid-area: phone;
    margin-top: 2.1rem;
    @media (max-width: 1100px) {
      margin-top: 1.7rem;
    }
  }
  &__legend {
    @include sr-only;
  }
}

.assistive-technology {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  &__title {
    margin-top: 4rem;
  }
  &__warning {
    width: 80%;
    align-self: center;
  }
  &__isUsed {
    display: flex;
    flex-direction: column;
    margin-top: 2.8rem;
  }
  &__isUsedTitle {
    font-size: 1.2rem;
  }
  &__isUsedItems {
    display: flex;
    justify-content: space-between;
    max-width: 35rem;
    margin-top: 0.3rem;
    margin-left: -0.2rem;
  }
  &__isUsedItem {
    width: auto;
  }
  &__type {
    display: flex;
    flex-direction: column;
    margin-top: 2.6rem;
  }
  &__typeTitle {
    font-size: 1.2rem;
  }
  &__typeItems {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    margin-top: 0.3rem;
    margin-left: -0.7rem;
  }
  &__typeItem {
    width: auto;
    margin-right: 0.4rem;
    margin-top: 0.1rem;
  }
  &__nameAndVersion {
    display: flex;
    flex-direction: column;
    margin-top: 2.6rem;
  }
  &__nameAndVersionTitle {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
  &__nameAndVersionItems {
    display: flex;
    flex-direction: column;
  }
  &__nameAndVersionItem {
    margin-top: 1.9rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  &__nameAndVersionItemLegend {
    font-size: 1.15rem;
    flex-basis: 20%;
    margin-top: 0.4rem;
  }
  &__nameAndVersionField {
    flex-basis: 35%;
  }

  @media (max-width: 1150px) {
    &__typeItems {
      flex-direction: column;
    }
    &__nameAndVersionItemLegend {
      flex-basis: 100%;
    }
    &__nameAndVersionField {
      flex-basis: 47%;
    }
  }

  @media (max-width: 900px) {
    &__nameAndVersionItem {
      flex-direction: column;
      align-items: stretch;
    }
    &__nameAndVersionItemLegend {
      flex-basis: auto;
    }
    &__nameAndVersionField {
      flex-basis: auto;
    }
    &__nameAndVersionFieldVersion {
      margin-top: 0.7rem;
    }
  }

  @media (max-width: 850px) {
    &__isUsedItems {
      flex-direction: column;
    }
  }

  @media (max-width: 750px) {
    &__warning {
      max-width: 90%;
      width: auto;
    }
  }

  @media (max-width: 450px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    &__warning {
      flex-direction: column;
      .warning__text {
        margin-left: 0;
        margin-top: 1rem;
      }
    }
  }
}

.dude_svg {
  width: 171px;
  height: 200px;
  &__circle {
    opacity: 0.28;
    filter: saturate(1.7);
    fill: var(--border-color);
  }
  &__head {
    fill: var(--border-color);
  }
  &__body {
    fill: var(--border-color);
  }
}
