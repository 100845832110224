.filter-mediation-requests {
  padding: 1.5rem 0 1.5rem 0;
  display: flex;

  &__text {
    padding: 0.3rem 0;
    font-size: 1.3rem;
    margin-right: 3rem;
  }
  &__statusContainer {
    max-width: 25rem;
    margin-right: 2.5rem;
  }
  &__option.last.MuiFormControl-root {
    min-width: 8.2rem;
  }

  @media (max-width: 1250px) {
    &__statusContainer {
      max-width: unset;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    &__option.MuiFormControl-root {
      width: 100%;
    }
    &__statusContainer {
      margin-right: 0;
      width: 100%;
    }
  }
}
