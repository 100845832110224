.password-reset-request {
  padding: 1.2rem 1.5rem 1.8rem 1.5rem;
  &__title {
    font-size: 1.6rem;
    text-align: center;
    margin: 0;
    margin-bottom: 0.2rem;
    padding: 0;
    @media (max-width: 400px) {
      font-size: 1.5rem;
    }
  }
  &__buttons {
    padding-top: 2.5rem;
    display: flex;
    flex-wrap: wrap;
  }
  &__submit.button,
  &__cancel.button {
    flex: auto;
    flex-basis: 170px;
  }
  .MuiInputLabel-root {
    margin-top: 1.1rem;
  }
}
