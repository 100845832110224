.information {
  font-size: 0.85rem;
  margin-top: 0;
  margin-bottom: 1.8rem;
  &__image {
    width: 1.4rem;
    padding-right: 0.5rem;
    margin-bottom: -0.3rem;
  }
}
