.radio-container {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.radio {
  width: 100%;

  &__label {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: -0.5em;
    margin-right: 1em;
    vertical-align: middle;
  }

  &__wrapper {
    color: var(--border-color);
    padding: 0.6rem;
    flex: 0 0 auto;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    position: relative;
    align-items: center;
    user-select: none;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;

    &:hover {
      background-color: rgba(62, 131, 51, 0.07);
    }
  }

  &__custom-input {
    display: flex;
    position: relative;

    &__svg {
      fill: currentColor;
      width: 1em;
      height: 1em;
      display: inline-block;
      font-size: 1.5rem;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      flex-shrink: 0;
      user-select: none;
    }

    &__inside {
      transform: scale(1);
      transition: transform 150ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      left: 0;
      position: absolute;
      fill: currentColor;
      width: 1em;
      height: 1em;
      display: inline-block;
      font-size: 1.5rem;
      flex-shrink: 0;
      user-select: none;
    }
  }

  &__input {
    top: 0;
    left: 0;
    width: 100%;
    cursor: inherit;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
  }

  &__input:not(:checked) + &__wrapper {
    color: rgba(0, 0, 0, 0.54);

    .radio__custom-input__inside {
      transform: scale(0);
      transition: transform 150ms cubic-bezier(0.4, 0, 1, 1) 0ms;
    }
  }
}

.radio__input:focus + .radio__wrapper {
  border: 0.2rem solid var(--border-color);
  padding: 0.2rem;
  margin: 0.2rem;
}
