.warning {
  display: flex;
  background-color: #f3f3f3;
  align-items: center;
  padding: 1rem 1.3rem;
  &__text {
    font-size: 0.93rem;
    margin: 0;
    margin-left: 1.3rem;
    line-height: 1.4;
  }
}
