.identification {
  display: flex;

  &__container {
    max-width: 500px;
    width: 500px;
    display: flex;
    flex-direction: column;
  }
}
