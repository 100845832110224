.button {
  font-family: Luciole, sans-serif;
  cursor: pointer;

  &__content {
    margin-bottom: -2px;
  }
}

.MuiButton-root {
  &.button {
    cursor: pointer;
    border-radius: 32px;
    border: 2px solid transparent;
    background-color: var(--button-background-color);
    color: var(--secondary-text-color);
    text-transform: none;
    margin: 2px 5px;
    padding: 5px 22px;

    .MuiButton-label {
      font-family: Luciole, sans-serif;
      margin-bottom: -2px;
    }

    &:hover,
    &:focus {
      background-color: var(--button-hover-color);
      border: 2px solid transparent;
    }
    .MuiTouchRipple-root {
      opacity: 0.4;
    }
  }
}

.button {
  &.MuiButton-outlined {
    background-color: transparent;
    color: var(--secondary-text-color);
    position: relative;
    z-index: 1;

    &::before,
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 32px;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    &::before {
      /* the border of the button */
      z-index: -1;
      border: 2px solid var(--secondary-text-color);
      left: -2px;
      opacity: 0.7;
    }
    &::after {
      /* the background of the button */
      z-index: -2;
    }
    &:focus::after,
    &:hover::after {
      background-color: var(--outlined-button-hover-color);
    }
  }
}
