.password-reset-confirm {
  display: flex;
  justify-content: center;
  &__content {
    background-color: white;
    padding: 2rem 3rem 2.5rem 3rem;
    max-width: 50rem;
    @media (max-width: 430px) {
      padding: 2rem 1.7rem 2.5rem 1.7rem;
    }
  }
  &__submit {
    margin-top: 2rem;
    margin-left: -5px;
  }
  &__field {
    margin-bottom: 1rem;
  }
}
