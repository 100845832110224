.terms-of-service {
  &__content {
    padding: 2.5rem 3.5rem 2.5rem 3.5rem;

    h1,
    h2 {
      margin-top: 2.6rem;
      margin-bottom: 1.4rem;
    }
    h3 {
      margin-top: 1.9rem;
      margin-bottom: 1rem;
    }
    h4 {
      margin-top: 1.6rem;
      margin-bottom: 0.9rem;
    }
    h5 {
      margin-top: 1.5rem;
      margin-bottom: 0.8rem;
    }
    h6 {
      margin-top: 1.4rem;
      margin-bottom: 0.7rem;
    }
    *:first-child {
      margin-top: 0;
    }
    *:last-child {
      margin-bottom: 0;
    }
  }
  &__h7 {
    margin-top: 1.4rem;
    margin-bottom: 0.7rem;
    font-size: 0.67rem;
    font-weight: bold;
  }
  &__link {
    padding-top: 0.1rem;
    &:focus-visible {
      outline: 2px solid currentColor;
      outline-offset: 2px;
    }
  }

  @media (max-width: 750px) {
    &__content {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
  }

  @media (max-width: 550px) {
    &__content {
      padding-top: 1.7rem;
      padding-bottom: 1.7rem;
    }
  }

  @media (max-width: 430px) {
    &__content {
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
    }
  }
}
