.mediation-request {
  &__content {
    border: 1px solid #c9c9c9;
    overflow: hidden;
  }
  &__title {
    margin: 1rem 0 1.2rem 0;
  }
}

.mediation-tabpanel {
  &__title {
    margin: 0;
    text-align: center;
    position: relative;
    font-size: 1.65rem;
    font-weight: normal;
    @media (max-width: 750px) {
      font-size: 1.4rem;
    }
    @media (max-width: 450px) {
      font-size: 1.3rem;
    }
  }
  &__title-container {
    background-color: #f3f3f3;
    padding: 1.6rem 1rem 1.3rem 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.04);
  }
  &__buttons {
    padding: 2.4rem;
    display: flex;
    justify-content: center;
    @media (max-width: 750px) {
      flex-direction: column;
      button.MuiButtonBase-root {
        margin-bottom: 0.3rem;
      }
    }
    @media (max-width: 500px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    @media (max-width: 430px) {
      padding-left: 0;
      padding-right: 0;
      button.MuiButtonBase-root {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    &.three-button {
      @media (max-width: 1050px) {
        flex-direction: column;
        padding-left: 5rem;
        padding-right: 5rem;
        button.MuiButtonBase-root {
          margin-bottom: 0.3rem;
        }
      }
      @media (max-width: 750px) {
        padding-left: 3rem;
        padding-right: 3rem;
      }
      @media (max-width: 500px) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
      @media (max-width: 430px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .MuiButton-label .MuiSvgIcon-root {
      font-size: 1.55rem;
      margin-top: -1px;
    }
  }
  &__clear-button.MuiButton-root {
    @include clear-button-colors;
  }
  &__submit-button.MuiButton-root {
    @include submit-button-colors;
  }
  &__back-button.MuiButton-root {
    @include back-button-colors;
  }
  &__form {
    background-color: white;
    padding: 2rem 2rem 0 2rem;
    @media (max-width: 400px) {
      padding: 2rem 1.5rem 0 1.5rem;
    }
  }
  &__section {
    margin: 2rem 0;
  }
  &__assistive-technology {
    margin-bottom: 0.7rem;
  }
}
