.customer-layout {
  display: grid;
  min-height: 100vh;
  grid-template:
    "quick-links" auto
    "header" auto
    "main" 1fr
    "footer" auto /
    1fr;

  &__quick-links {
    grid-area: quick-links;
  }

  &__header {
    grid-area: header;
  }

  &__main {
    grid-area: main;
  }

  &__footer {
    grid-area: footer;
  }
}

.admin-layout {
  display: grid;
  min-height: 100vh;
  grid-template:
    "quick-links quick-links" 25px
    "aside main" auto /
    auto 1fr;

  &__quick-links {
    grid-area: quick-links;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  &__aside {
    grid-area: aside;
  }

  &__main {
    grid-area: main;
  }
}
