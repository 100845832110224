.about-organization {
  .MuiInputLabel-root {
    margin-top: 1.1rem;
  }
  .label {
    font-size: $label-size;
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;
  }
}
