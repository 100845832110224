.signup {
  &__buttons {
    padding-top: 3rem;
    display: flex;
    flex-wrap: wrap;
  }
  &__termsOfService {
    display: flex;
    align-items: flex-start;
    margin-left: -10px;
    margin-top: 2rem;
  }
  &__termsOfService-link {
    padding-top: 0.1rem;
    &:focus-visible {
      outline: 2px solid currentColor;
      outline-offset: 2px;
    }
  }
  &__termsOfService-label {
    padding-top: 9px;
  }
  &__error {
    color: #ee0701;
  }
  &__submit.button,
  &__cancel.button {
    flex: auto;
    flex-basis: 0;
    flex-basis: 145px;
  }
  .MuiInputLabel-root {
    margin-top: 0.3rem;
  }
}
