.custom-organization-text {
  padding: 0.8rem 7% 1.5rem 7%;
  @media (max-width: 430px) {
    padding: 0.8rem 4% 1.5rem 4%;
  }
  &__content {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 0px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
    padding: 1.5rem 2rem 1rem 2rem;
    font-size: 1rem;
  }
  h1 {
    margin: 0;
    margin-bottom: 1.4rem;
    font-size: 1.75rem;
    @media (max-width: 450px) {
      font-size: 1.6rem;
    }
  }
}
