.admin-navigation {
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.07);
  width: 22rem;
  height: calc(100vh - 25px);
  position: sticky;
  top: 25px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all ease 0.3s;
  transition-property: visibility, opacity, transform, width;

  &__list {
    list-style-type: none;
    padding: 0;
    padding-bottom: 4.5rem;
  }
  &__item {
    margin-top: 1.5rem;
    padding: 0.5rem 0rem 0.5rem 1.5rem;
  }
  &__menu-icon {
    background: white;
    border-radius: 5px;
    border: unset;
    color: var(--button-background);
    display: none;
    cursor: pointer;
    position: fixed;
    z-index: 11;
    top: 2.5rem;
    left: 0.7rem;
    @media (max-width: 1100px) {
      display: block;
    }
  }
  &__home {
    border-bottom: 1px solid #ebebeb;
    display: flex;
    justify-content: center;
    margin: 1.5rem 0 1.8rem 0;
    padding: 0;
  }
  &__logo-background {
    position: absolute;
    padding: 1rem 3rem 1.5rem 3rem;
  }
  &__logo {
    position: relative;
    z-index: 1;
    padding: 1rem 3rem 1.5rem 3rem;
  }
  &__logo-small {
    height: 3rem;
    padding: 1rem 1rem 1.5rem 1rem;
  }
  &__toggle-button {
    background: white;
    width: 100%;
    border: none;
    border-top: 1px solid #ebebeb;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 1.3rem;
    padding-top: 1.5rem;
    color: #6d6f73;
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  &__open-icon,
  &__close-icon {
    fill: #6d6f73;
  }
  &__open-icon {
    height: 1.9rem;
    width: 1.9rem;
    padding-right: 0.4rem;
  }
  &__close-icon {
    height: 1.3rem;
    width: 1.3rem;
    padding-right: 0.7rem;
    margin-top: 0.1rem;
    margin-bottom: 0.3rem;
  }
  &__title {
    @include sr-only;
  }
  &__title-logo {
    border-bottom: 1px solid #ebebeb;
    display: flex;
    justify-content: center;
    margin: 1.5rem 0 0.3rem;
    padding: 0;
  }
  &__title-logo-link {
    display: block;
  }

  @media (max-width: 1100px) {
    background-color: white;
    padding-top: 1.55rem;
    position: fixed;
    z-index: 2;
    height: 100%;
    box-sizing: border-box;

    &.menu-opened {
      visibility: visible;
      opacity: 1;
      transform: translateX(0px);
    }
    &.menu-closed {
      visibility: hidden;
      opacity: 0;
      transform: translateX(-100%);
    }
  }
  @media (min-width: 1100px) {
    &.desktop-closed {
      width: 5.5rem;
    }
  }

  @media (max-width: 400px) {
    width: 20rem;
    &__item {
      padding: 0.5rem 0rem 0.5rem 1rem;
    }
    &__menu-icon {
      top: 2.5rem;
    }
  }
}

.admin-navigation-item {
  position: relative;
  &.closed {
    text-align: center;
    padding: 1.5rem 1.5rem;
    &.active {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--step-color);
        opacity: 0.2;
        z-index: -1;
      }
    }
  }
  &__list {
    list-style-type: none;
    padding: 0 0 0.3rem 1rem;
    overflow: hidden;
    &.hidden {
      display: none;
    }
  }
  &__list-container {
    overflow: hidden;
  }
  &__item {
    opacity: 0.8;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 8px;
      height: 100%;
      background-color: var(--step-color);
      transform: translateX(-8px);
      transition: transform 0.4s ease;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--step-color);
      opacity: 0;
      transition: opacity 0.3s ease;
      z-index: -1;
    }
    &:hover,
    &:focus,
    &.active {
      &::before {
        transform: translateX(0);
      }
      &::after {
        opacity: 0.2;
      }
    }
  }
  &__button {
    color: #2f2f2f;
    font-size: 1.22rem;
    font-variant: small-caps;
    font-weight: bold;
    margin-bottom: 0.9rem;
    background: none;
    border: none;
    z-index: 10;
  }
  &__icon-before {
    margin-bottom: -2px;
    padding-right: 0.7rem;
  }
  &__icon-after.MuiSvgIcon-root {
    margin-bottom: -0.55rem;
    font-size: 2rem;
    padding-left: 0.7rem;
  }
  &__link {
    text-decoration: none;
    color: unset;
    display: inline-block;
    padding: 0.7rem 0.75rem 0.7rem 1.25rem;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }
  &__title {
    font-size: unset;
    font-weight: unset;
    margin: unset;
    padding: unset;
  }

  @media (max-width: 400px) {
    &__button {
      font-size: 1.15rem;
    }
  }
}
