.login {
  &__buttons-container {
    display: flex;
    flex-direction: column-reverse;
  }
  &__buttons {
    padding-top: 50px;
    display: flex;
    flex-wrap: wrap;
  }
  &__withPassword {
    margin-top: 1.6rem;
    margin-left: -10px;
    label {
      position: relative;
      top: 1px;
    }
  }
  &__submit.button,
  &__cancel.button {
    flex: auto;
    flex-basis: 145px;
  }
  &__forgotten-password-container {
    padding: 1rem 0rem 0 0rem;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
  }
  &__forgotten-password {
    text-decoration: none;
    padding: 0.4rem 0.5rem 0.3rem 0.5rem;
    color: #dd1d20;
  }
  .MuiInputLabel-root {
    margin-top: 1.1rem;
  }
}
