.admin-trace-reports {
  &__table-container {
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.07);
    overflow: hidden;
    border: 1px solid #ebebeb;
  }
  &__add-button.button {
    margin: 1rem 0 1.3rem 0;
  }
  &__entity {
    display: flex;
    flex-direction: column;
  }
  &__entity-type {
    color: rgb(55, 55, 95);
    font-weight: bold;
  }
  &__comment {
    word-break: break-word;
  }
  &__link {
    white-space: nowrap;
    padding: 0.5rem;
    margin-left: -0.5rem;
    font-size: 0.95rem;
    color: #150bab;
  }
  &__action-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  &__remove-button.button,
  &__edit-button.button {
    flex: 1;
    min-width: 6rem;
    max-width: 15rem;
  }

  @media (max-width: 400px) {
    &__link {
      white-space: unset;
      line-height: 2rem;
    }
  }
}

.admin-trace-reports-form {
  &__container {
    padding: 1.5rem 2rem 1.5rem 2rem;
  }
  &__field {
    margin-top: 1.3rem;
    &.entity {
      display: flex;
    }
  }
  &__entity-type {
    flex: 1;
  }
  &__entity-name {
    flex: 1.3;
    margin-left: 1.5rem;
  }
  &__attached-file {
    margin-top: 1.35rem;
    .label {
      font-size: $label-size;
      padding: 0.5rem 1rem 0.5rem 0;
      display: inline-block;
    }
  }
  &__remove-file {
    margin-top: 0.5rem;
    margin-left: -0.78rem;
    display: flex;
    align-items: center;
    .label {
      font-size: $label-size;
      padding: 0.5rem 1rem 0.5rem 0;
      display: inline-block;
      margin-bottom: -0.2rem;
    }
  }
  &__attached-file-input {
    font-size: 1.03rem;
    width: 100%;
  }
  .MuiFormHelperText-root.Mui-error {
    width: 100%;
  }
  &__submit.button {
    flex-basis: 190px;
  }
  &__cancel.button {
    flex-basis: 105px;
  }

  @media (max-width: 600px) {
    &__container {
      padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    }
    &__field {
      &.entity {
        flex-direction: column;
      }
    }
    &__entity-name {
      margin-top: 1.3rem;
      margin-left: 0;
    }
  }
}
